/******************************************************
 *              ⚠️      COMMENTS      ⚠️              *
 *                                                    *
 *   typed: *name* -> generates typed interface,      *
 					  with given name, for variable   *
 *   class property -> (e.g. width) helper to         *
 					   generate class property        *
 *   skip -> skip checking                            *
 ******************************************************/
:root {
	/* font-family */
	--font-family: 'Inter Regular';
	--font-family-kone: 'Kone Information';
	/**/

	/* font-weight */
	--font-weight-semi-bold: 600;
	--font-weight-normal: 400;
	/**/

	/* typed: font-size */
	/* font-size */
	--font-size-xs: 10px;
	--font-size-sm: 12px;
	--font-size-normal: 14px;
	--font-size-md: 16px;
	--font-size-lg: 18px;
	--font-size-super-lg: 20px;
	--font-size-xl: 24px;
	--font-size-super-xl: 32px;
	--font-size-super-lg-xl: 36px;
	--icon-size: 18px;
	/**/

	/* width */
	--page-min-width: 1000px;
	--menu-width: 250px;
	--notification-width: 270px;
	--equipment-side-width: 285px;
	/**/

	/* height */
	--button-height: 32px;
	--button-height-small: 36px;
	--item-height: 60px;
	--header-height: 70px;
	/**/

	/* typed: border-radius */
	/* border-radius */
	--border-radius: 14px;
	--border-radius-md: 12px;
	--border-radius-lg: 28px;
	--border-radius-btn: 8px;
	--border-radius-small: 4px;
	/**/

	/* margin | margin-inline | margin-block | margin-bottom | margin-top | margin-right | margin-left  */
	--page-margin: 18px;
	--page-margin-half: calc(var(--page-margin) / 2);
	--item-margin: 12px;
	--item-margin-half: calc(var(--item-margin) / 2);
	--icon-btn-margin: 6px;
	/**/

	/* padding | padding-inline | padding-block | padding-bottom | padding-top | padding-right | padding-left  */
	--container-padding-md: 22px;
	--container-padding: 18px;
	--container-padding-sm: 12px;
	--container-padding-super-sm: 6px;
	/**/

	/* transition-duration */
	--transition-duration: 400ms;
	--transition-duration-half: 250ms;
	/**/

	/* typed: color */
	/* color */
	--primary-color: #1450f5;
	--warn-color: #ef5c48;
	--orange-color: #f18d13;
	--green-color: #2abb7f;
	--purple-color: #8f7ee7;
	--teal-color: #37b4c3;
	--yellow-color: #cf9f02;
	--magenta-color: #da62ac;
	--ice-blue-color: #d2f5ff;
	--black-white: #ffffff;
	/**/
}

.light-theme {
	/* typed: background-color */
	/* background-color */
	--app-background: #eeeeee;
	--primary-layer: #f1f3f4;
	--secondary-layer: #f1f1f1;
	--tertiary-layer: #e1e1e1;
	--fourth-layer: #d9d9d9;
	--fifth-layer: #c8c8c8;
	--background-grey: #f8f8f8;
	--background-blue-light: #f4f6fe;
	--button-color: #f6f6f6;
	--loading-background: rgba(0, 0, 0, 0.04);
	--loading-background-strong: rgba(0, 0, 0, 0.08);
	/**/

	/* typed: text-color */
	/* color */
	--text-color: rgba(0, 0, 0, 0.82);
	--text-color-soft: rgba(0, 0, 0, 0.57);
	--text-color-disabled: rgba(0, 0, 0, 0.38);
	--hover-color: rgba(0, 0, 0, 0.04);
	--strong-hover-color: rgba(0, 0, 0, 0.08);
	--divider-color: rgba(0, 0, 0, 0.15);
	--divider-stronger-color: rgba(0, 0, 0, 0.54);
	--disabled-color: rgba(0, 0, 0, 0.3);
	--color-inverse: #000000;
	--text-color-inverse: rgba(255, 255, 255, 0.96);
	--text-color-inverse-soft: rgba(255, 255, 255, 0.6);
	--box-shadow-color: rgba(0, 0, 0, 0.16);
	/**/
}


.dark-theme {
	/* skip */
	--app-background: #222222;
	--primary-layer: #202020;
	--secondary-layer: #2b2b2b;
	--tertiary-layer: #3e3e3e;
	--fourth-layer: #505050;
	--fifth-layer: #606060;
	--background-grey: #222222;
	--background-blue-light: #16192f;
	--button-color: #333333;
	--loading-background: rgba(255, 255, 255, 0.07);
	--loading-background-strong: rgba(255, 255, 255, 0.2);
	--text-color: rgba(255, 255, 255, 0.96);
	--text-color-soft: rgba(255, 255, 255, 0.6);
	--text-color-disabled: rgba(255, 255, 255, 0.4);
	--hover-color: rgba(255, 255, 255, 0.07);
	--strong-hover-color: rgba(255, 255, 255, 0.2);
	--divider-color: rgba(255, 255, 255, 0.15);
	--divider-stronger-color: rgba(255, 255, 255, 0.54);
	--disabled-color: rgba(255, 255, 255, 0.2);
	--color-inverse: #ffffff;
	--text-color-inverse: rgba(0, 0, 0, 0.77);
	--text-color-inverse-soft: rgba(0, 0, 0, 0.57);
	--box-shadow-color: rgba(255, 255, 255, 0.16);
	/**/
}
