.loading .item {
	width: 100px;
	height: var(--font-size-normal);
	cursor: wait;
	background-color: var(--loading-background);
	background-image: linear-gradient(
		90deg,
		var(--loading-background) 0px,
		var(--loading-background-strong) 50%,
		var(--loading-background) 100%
	);
	background-repeat: no-repeat;
	background-size: 200% 100%;
	animation: loading 1.5s infinite;
}

.loading .item.height {
	height: 20px;
}

.loading .item.height.medium {
	height: 16px;
}

.loading .item.height-large {
	height: 25px;
}

.loading .item.height.button {
	height: var(--button-height);
}

.loading .item.font-size-xl {
	height: var(--font-size-xl);
}

.loading .item.font-size-sm {
	height: var(--font-size-sm);
}

.loading .item.font-size-md {
	height: var(--font-size-md);
}

.loading .item.small {
	width: 60px;
}

.loading .item.width-large {
	width: 170px;
}

.loading .item.super-large {
	width: 220px;
}

.loading .item.width-100 {
	width: 100%;
}

.loading .item.icon {
	--loading-icon-size: var(--icon-size);
	width: var(--loading-icon-size);
	height: var(--loading-icon-size);
	border-radius: 50%;
}

.loading .item.icon.icon-size {
	--loading-icon-size: var(--icon-size);
}

.loading .item.icon.large {
	--loading-icon-size: 44px;
}

.loading .item.icon.super-large {
	--loading-icon-size: 50px;
}

.loading .item.margin-bottom {
	margin-bottom: 6px;
}

.loading-container {
	background-color: var(--secondary-layer);
	padding: var(--container-padding);
	border-radius: var(--border-radius);
	cursor: wait;
}

.loading-container.form {
	height: 56px; /* form height */
}

.loading-container.button {
	width: 100%;
	border-radius: var(--border-radius-btn);
	height: var(--button-height);
}

@keyframes loading {
	0% {
		background-position: 200% 0; /* Start from the left */
	}
	100% {
		background-position: -200% 0; /* End at the right */
	}
}
