
/******************************************************
 *                                                    *
 *  ⚠️  ATTENTION: THIS CONTENT IS AUTO-GENERATED  ⚠️ *
 *                  (css-variables-generator.js)              *
 *                                                    *
 *        DO NOT MODIFY ClASS MANUALLY!               *
 ******************************************************/

/* font-family */

.font-family {
	font-family: var(--font-family);
}

.font-family-kone {
	font-family: var(--font-family-kone);
}

/* font-weight */

.font-weight-semi-bold {
	font-weight: var(--font-weight-semi-bold);
}

.font-weight-normal {
	font-weight: var(--font-weight-normal);
}

/* font-size */

.font-size-xs {
	font-size: var(--font-size-xs);
}

.font-size-sm {
	font-size: var(--font-size-sm);
}

.font-size-normal {
	font-size: var(--font-size-normal);
}

.font-size-md {
	font-size: var(--font-size-md);
}

.font-size-lg {
	font-size: var(--font-size-lg);
}

.font-size-super-lg {
	font-size: var(--font-size-super-lg);
}

.font-size-xl {
	font-size: var(--font-size-xl);
}

.font-size-super-xl {
	font-size: var(--font-size-super-xl);
}

.font-size-super-lg-xl {
	font-size: var(--font-size-super-lg-xl);
}

.icon-size-font {
	font-size: var(--icon-size);
}

/* width */

.page-min-width {
	width: var(--page-min-width);
}

.menu-width {
	width: var(--menu-width);
}

.notification-width {
	width: var(--notification-width);
}

.equipment-side-width {
	width: var(--equipment-side-width);
}

/* height */

.button-height {
	height: var(--button-height);
}

.button-height-small {
	height: var(--button-height-small);
}

.item-height {
	height: var(--item-height);
}

.header-height {
	height: var(--header-height);
}

/* border-radius */

.border-radius {
	border-radius: var(--border-radius);
}

.border-radius-md {
	border-radius: var(--border-radius-md);
}

.border-radius-lg {
	border-radius: var(--border-radius-lg);
}

.border-radius-btn {
	border-radius: var(--border-radius-btn);
}

.border-radius-small {
	border-radius: var(--border-radius-small);
}

/* margin  |  margin-inline | margin-block | margin-bottom | margin-top | margin-right | margin-left */

.page-margin {
	margin: var(--page-margin);
}

.page-margin-inline {
	margin-inline: var(--page-margin);
}

.page-margin-block {
	margin-block: var(--page-margin);
}

.page-margin-bottom {
	margin-bottom: var(--page-margin);
}

.page-margin-top {
	margin-top: var(--page-margin);
}

.page-margin-right {
	margin-right: var(--page-margin);
}

.page-margin-left {
	margin-left: var(--page-margin);
}

.page-margin-half {
	margin: var(--page-margin-half);
}

.page-margin-half-inline {
	margin-inline: var(--page-margin-half);
}

.page-margin-half-block {
	margin-block: var(--page-margin-half);
}

.page-margin-half-bottom {
	margin-bottom: var(--page-margin-half);
}

.page-margin-half-top {
	margin-top: var(--page-margin-half);
}

.page-margin-half-right {
	margin-right: var(--page-margin-half);
}

.page-margin-half-left {
	margin-left: var(--page-margin-half);
}

.item-margin {
	margin: var(--item-margin);
}

.item-margin-inline {
	margin-inline: var(--item-margin);
}

.item-margin-block {
	margin-block: var(--item-margin);
}

.item-margin-bottom {
	margin-bottom: var(--item-margin);
}

.item-margin-top {
	margin-top: var(--item-margin);
}

.item-margin-right {
	margin-right: var(--item-margin);
}

.item-margin-left {
	margin-left: var(--item-margin);
}

.item-margin-half {
	margin: var(--item-margin-half);
}

.item-margin-half-inline {
	margin-inline: var(--item-margin-half);
}

.item-margin-half-block {
	margin-block: var(--item-margin-half);
}

.item-margin-half-bottom {
	margin-bottom: var(--item-margin-half);
}

.item-margin-half-top {
	margin-top: var(--item-margin-half);
}

.item-margin-half-right {
	margin-right: var(--item-margin-half);
}

.item-margin-half-left {
	margin-left: var(--item-margin-half);
}

.icon-btn-margin {
	margin: var(--icon-btn-margin);
}

.icon-btn-margin-inline {
	margin-inline: var(--icon-btn-margin);
}

.icon-btn-margin-block {
	margin-block: var(--icon-btn-margin);
}

.icon-btn-margin-bottom {
	margin-bottom: var(--icon-btn-margin);
}

.icon-btn-margin-top {
	margin-top: var(--icon-btn-margin);
}

.icon-btn-margin-right {
	margin-right: var(--icon-btn-margin);
}

.icon-btn-margin-left {
	margin-left: var(--icon-btn-margin);
}

/* padding  |  padding-inline | padding-block | padding-bottom | padding-top | padding-right | padding-left */

.container-padding-md {
	padding: var(--container-padding-md);
}

.container-padding-md-inline {
	padding-inline: var(--container-padding-md);
}

.container-padding-md-block {
	padding-block: var(--container-padding-md);
}

.container-padding-md-bottom {
	padding-bottom: var(--container-padding-md);
}

.container-padding-md-top {
	padding-top: var(--container-padding-md);
}

.container-padding-md-right {
	padding-right: var(--container-padding-md);
}

.container-padding-md-left {
	padding-left: var(--container-padding-md);
}

.container-padding {
	padding: var(--container-padding);
}

.container-padding-inline {
	padding-inline: var(--container-padding);
}

.container-padding-block {
	padding-block: var(--container-padding);
}

.container-padding-bottom {
	padding-bottom: var(--container-padding);
}

.container-padding-top {
	padding-top: var(--container-padding);
}

.container-padding-right {
	padding-right: var(--container-padding);
}

.container-padding-left {
	padding-left: var(--container-padding);
}

.container-padding-sm {
	padding: var(--container-padding-sm);
}

.container-padding-sm-inline {
	padding-inline: var(--container-padding-sm);
}

.container-padding-sm-block {
	padding-block: var(--container-padding-sm);
}

.container-padding-sm-bottom {
	padding-bottom: var(--container-padding-sm);
}

.container-padding-sm-top {
	padding-top: var(--container-padding-sm);
}

.container-padding-sm-right {
	padding-right: var(--container-padding-sm);
}

.container-padding-sm-left {
	padding-left: var(--container-padding-sm);
}

.container-padding-super-sm {
	padding: var(--container-padding-super-sm);
}

.container-padding-super-sm-inline {
	padding-inline: var(--container-padding-super-sm);
}

.container-padding-super-sm-block {
	padding-block: var(--container-padding-super-sm);
}

.container-padding-super-sm-bottom {
	padding-bottom: var(--container-padding-super-sm);
}

.container-padding-super-sm-top {
	padding-top: var(--container-padding-super-sm);
}

.container-padding-super-sm-right {
	padding-right: var(--container-padding-super-sm);
}

.container-padding-super-sm-left {
	padding-left: var(--container-padding-super-sm);
}

/* transition-duration */

.transition-duration {
	transition-duration: var(--transition-duration);
}

.transition-duration-half {
	transition-duration: var(--transition-duration-half);
}

/* color */

.primary-color {
	color: var(--primary-color);
}

.warn-color {
	color: var(--warn-color);
}

.orange-color {
	color: var(--orange-color);
}

.green-color {
	color: var(--green-color);
}

.purple-color {
	color: var(--purple-color);
}

.teal-color {
	color: var(--teal-color);
}

.yellow-color {
	color: var(--yellow-color);
}

.magenta-color {
	color: var(--magenta-color);
}

.ice-blue-color {
	color: var(--ice-blue-color);
}

.black-white-color {
	color: var(--black-white);
}

/* background-color */

.app-background-color {
	background-color: var(--app-background);
}

.primary-layer-background-color {
	background-color: var(--primary-layer);
}

.secondary-layer-background-color {
	background-color: var(--secondary-layer);
}

.tertiary-layer-background-color {
	background-color: var(--tertiary-layer);
}

.fourth-layer-background-color {
	background-color: var(--fourth-layer);
}

.fifth-layer-background-color {
	background-color: var(--fifth-layer);
}

.background-grey-color {
	background-color: var(--background-grey);
}

.background-blue-light-color {
	background-color: var(--background-blue-light);
}

.button-color-background {
	background-color: var(--button-color);
}

.loading-background-color {
	background-color: var(--loading-background);
}

.loading-background-strong-color {
	background-color: var(--loading-background-strong);
}

/* color */

.text-color {
	color: var(--text-color);
}

.text-color-soft {
	color: var(--text-color-soft);
}

.text-color-disabled {
	color: var(--text-color-disabled);
}

.hover-color {
	color: var(--hover-color);
}

.strong-hover-color {
	color: var(--strong-hover-color);
}

.divider-color {
	color: var(--divider-color);
}

.divider-stronger-color {
	color: var(--divider-stronger-color);
}

.disabled-color {
	color: var(--disabled-color);
}

.color-inverse {
	color: var(--color-inverse);
}

.text-color-inverse {
	color: var(--text-color-inverse);
}

.text-color-inverse-soft {
	color: var(--text-color-inverse-soft);
}

.box-shadow-color {
	color: var(--box-shadow-color);
}