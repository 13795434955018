@font-face {
	font-family: 'Inter Regular';
	src: url(../assets/fonts/Inter-Regular.ttf) format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Inter Regular';
	src: url(../assets/fonts/Inter-SemiBold.ttf) format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Kone Information';
	src: url(../assets/fonts/KONE_Information.ttf) format('truetype');
	font-weight: 400;
	font-style: normal;
}
