@keyframes slide-animation-back-left {
	to {
		transform: translateX(100%);
	}
}

@keyframes slide-animation-back-right {
	from {
		transform: translateX(-100%);
	}
}

@keyframes slide-from-right {
	from {
		transform: translateX(100%);
	}
}

@keyframes slide-to-left {
	to {
		transform: translateX(-100%);
	}
}

.allow-transition {
	&::view-transition-old(root) {
		animation: var(--transition-duration-half) ease-out slide-to-left;
	}

	&::view-transition-new(root) {
		animation: var(--transition-duration-half) ease-out slide-from-right;
	}
}

.allow-transition-reverse {
	&::view-transition-old(root) {
		animation: var(--transition-duration-half) ease-out slide-animation-back-left;
	}

	&::view-transition-new(root) {
		animation: var(--transition-duration-half) ease-out slide-animation-back-right;
	}
}
