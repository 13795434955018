@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.fade-in {
	animation: fadeIn 1s;
}
