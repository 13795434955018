.light-theme {
	--background-container-color: var(--app-background);
}

.dark-theme {
	--background-container-color: var(--app-background);
}

.light-theme,
.dark-theme,
:root {
	--mdc-typography-font-family: var(--font-family);
	--mdc-typography-body1-font-family: var(--font-family);
	--mdc-typography-body2-font-family: var(--font-family);
	--mdc-typography-body1-font-size: var(--font-size-normal);
	--mdc-typography-body2-font-size: var(--font-size-normal);
	--mdc-typography-body1-line-height: var(--font-size-normal);
	--mdc-typography-body2-line-height: var(--font-size-normal);
	--mdc-typography-subtitle1-font-size: var(--font-size-normal);
	--mdc-typography-subtitle1-line-height: var(--font-size-normal);
	--mdc-typography-subtitle2-font-size: var(--font-size-normal);
	--mdc-typography-subtitle2-line-height: var(--font-size-normal);
	--mdc-shape-medium: var(--border-radius);
	--mdc-shape-small: var(--border-radius-btn);
	--mdc-typography-body1-letter-spacing: 0;
	--mdc-typography-body2-letter-spacing: 0;
	--mdc-typography-subtitle1-letter-spacing: 0;
	--mdc-typography-subtitle2-letter-spacing: 0;
	--mdc-theme-text-primary-on-background: var(--text-color);
	--mdc-theme-text-secondary-on-background: var(--text-color-soft);
	--mdc-theme-text-disabled-on-background: var(--text-color-disabled);
	--mdc-theme-text-icon-on-background: var(--button-color);
	--mdc-icon-button-icon-color: var(--button-color);
	--mat-select-panel-background-color: var(--background-container-color);
	--mat-select-enabled-trigger-text-color: var(--text-color);
	--mat-select-disabled-trigger-text-color: var(--text-color-disabled);
	--mat-select-placeholder-text-color: var(--text-color-soft);
	--mat-select-enabled-arrow-color: var(--text-color-soft);
	--mat-select-disabled-arrow-color: var(--disabled-color);
	--mat-select-trigger-text-font: var(--font-family);
	--mat-select-trigger-text-line-height: 24px;
	--mat-select-trigger-text-size: var(--font-size-normal);
	--mat-select-trigger-text-tracking: 0;
	--mdc-theme-on-surface: var(--text-color);
	--mat-paginator-container-text-color: var(--text-color);
	--mat-paginator-container-background-color: var(--background-container-color);
	--mat-paginator-enabled-icon-color: var(--text-color-soft);
	--mat-paginator-disabled-icon-color: var(--disabled-color);
	--mat-option-label-text-color: var(--text-color);
	--mat-option-label-text-line-height: var(--font-size-normal);
	--mat-option-label-text-size: var(--font-size-normal);
	--mat-option-label-text-tracking: 0;
	--mat-option-hover-state-layer-color: var(--hover-color);
	--mat-option-focus-state-layer-color: var(--hover-color);
	--mat-option-selected-state-layer-color: var(--hover-color);
	--mat-menu-item-label-text-size: var(--font-size-normal);
	--mat-menu-item-label-text-line-height: var(--font-size-normal);
	--mat-menu-item-label-text-tracking: 0;
	--mat-menu-item-label-text-color: var(--text-color);
	--mat-menu-item-icon-color: var(--text-color);
	--mat-menu-item-hover-state-layer-color: var(--hover-color);
	--mat-menu-item-focus-state-layer-color: var(--hover-color);
	--mat-menu-container-color: var(--background-container-color);
	--mat-menu-container-shape: var(--border-radius);
	--mat-table-row-item-label-text-font: var(--font-family);
	--mat-table-header-headline-font: var(--font-family);
	--mat-table-header-headline-line-height: var(--font-size-normal);
	--mat-table-header-headline-size: var(--font-size-sm);
	--mat-table-header-headline-tracking: 0;
	--mat-table-header-headline-color: var(--text-color-soft);
	--mat-table-row-item-label-text-color: var(--text-color);
	--mat-table-row-item-outline-color: var(--divider-color);
	--mat-table-row-item-label-text-line-height: var(--font-size-normal);
	--mat-table-row-item-label-text-size: var(--font-size-normal);
	--mat-table-row-item-label-text-tracking: 0;
	--mat-table-footer-supporting-text-line-height: var(--font-size-normal);
	--mat-table-footer-supporting-text-size: var(--font-size-normal);
	--mat-table-footer-supporting-text-tracking: 0;
	--mdc-filled-text-field-label-text-size: var(--font-size-normal);
	--mdc-filled-text-field-label-text-tracking: 0;
	--mdc-outlined-text-field-label-text-size: var(--font-size-normal);
	--mdc-outlined-text-field-label-text-tracking: 0;
	--mat-form-field-container-text-line-height: var(--font-size-normal);
	--mat-form-field-container-text-size: var(--font-size-normal);
	--mat-form-field-container-text-tracking: 0;
	--mat-form-field-outlined-label-text-populated-size: var(--font-size-normal);
	--mat-form-field-subscript-text-line-height: var(--font-size-sm);
	--mat-form-field-subscript-text-size: var(--font-size-sm);
	--mat-form-field-subscript-text-tracking: 0;
	--mdc-filled-text-field-container-color: var(--text-color);
	--mdc-filled-text-field-disabled-container-color: var(--text-color);
	--mdc-filled-text-field-label-text-color: var(--text-color-soft);
	--mdc-filled-text-field-disabled-label-text-color: var(--text-color-disabled);
	--mdc-filled-text-field-input-text-color: var(--text-color);
	--mdc-filled-text-field-disabled-input-text-color: var(--text-color-disabled);
	--mdc-filled-text-field-input-text-placeholder-color: var(--text-color-soft);
	--mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
	--mdc-filled-text-field-disabled-active-indicator-color: var(--disabled-color);
	--mdc-filled-text-field-hover-active-indicator-color: var(--text-color);
	--mdc-outlined-text-field-label-text-color: var(--text-color-soft);
	--mdc-outlined-text-field-hover-label-text-color: var(--text-color-soft);
	--mdc-outlined-text-field-disabled-label-text-color: var(--text-color-disabled);
	--mdc-outlined-text-field-input-text-color: var(--text-color);
	--mdc-outlined-text-field-disabled-input-text-color: var(--text-color-disabled);
	--mdc-outlined-text-field-input-text-placeholder-color: var(--text-color-soft);
	--mdc-outlined-text-field-outline-color: var(--divider-color);
	--mdc-outlined-text-field-disabled-outline-color: var(--disabled-color);
	--mdc-outlined-text-field-hover-outline-color: var(--text-color);
	--mat-form-field-disabled-input-text-placeholder-color: var(--text-color-disabled);
	--mat-form-field-container-height: calc(var(--button-height) + 8px);
	--mat-form-field-filled-with-label-container-padding-top: 4px;
	--mat-form-field-filled-with-label-container-padding-bottom: 4px;
	--mat-form-field-container-vertical-padding: 5px;
	--mat-divider-color: var(--divider-color);
	--mat-table-footer-container-height: var(--item-height);
	--mat-table-row-item-container-height: var(--item-height);
	--mat-paginator-container-size: var(--item-height);
	--mdc-text-button-disabled-label-text-color: var(--text-color-disabled) !important;
	--mdc-text-button-label-text-color: var(--text-color-disabled) !important;
	--mdc-checkbox-unselected-icon-color: var(--divider-stronger-color);
	--mdc-checkbox-unselected-pressed-icon-color: var(--divider-stronger-color);
	--mat-badge-background-color: var(--color-inverse);
	--mat-badge-text-color: var(--text-color-inverse);
	--mdc-outlined-text-field-container-shape: var(--border-radius-small);
	--mat-autocomplete-background-color: var(--background-container-color);
	--mdc-checkbox-selected-checkmark-color: white;
}

.mat-mdc-dialog-container {
	--mdc-dialog-subhead-font: var(--font-family);
	--mdc-dialog-subhead-line-height: var(--font-size-lg);
	--mdc-dialog-subhead-size: var(--font-size-lg);
	--mdc-dialog-supporting-text-font: var(--font-family);
	--mdc-dialog-supporting-text-line-height: var(--font-size-normal);
	--mdc-dialog-supporting-text-size: var(--font-size-normal);
	--mdc-dialog-container-color: var(--app-background);
	--mdc-dialog-subhead-color: var(--text-color);
	--mdc-dialog-supporting-text-color: var(--text-color);
	--mdc-dialog-container-shape: var(--border-radius) !important;
	--mdc-dialog-supporting-text-tracking: 0;
}

/* mat mdc checkbox */
.mat-mdc-checkbox.mat-accent,
.mat-mdc-checkbox.mat-primary {
	--mdc-checkbox-unselected-icon-color: var(--text-color-soft);
	--mdc-checkbox-unselected-pressed-icon-color: var(--text-color-soft);
	--mdc-checkbox-disabled-selected-icon-color: var(--text-color-disabled);
	--mdc-checkbox-disabled-unselected-icon-color: var(--text-color-disabled);
	--mdc-checkbox-unselected-focus-icon-color: var(--divider-stronger-color);
	--mdc-checkbox-unselected-hover-icon-color: var(--divider-stronger-color);
}

.mat-pseudo-checkbox,
.mdc-checkbox__background {
	border-radius: 4px !important;
	--size: 20px;
	width: var(--size) !important;
	height: var(--size) !important;
	top: calc((var(--mdc-checkbox-state-layer-size) - var(--size)) / 2) !important;
	left: calc((var(--mdc-checkbox-state-layer-size) - var(--size)) / 2) !important;
}

/* mat form field */
.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
	padding: 0 !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
	display: flex;
}

.mat-mdc-form-field-infix {
	z-index: 1;
	min-height: var(--mat-form-field-container-height);
	padding-top: var(--mat-form-field-filled-with-label-container-padding-top);
	padding-bottom: var(--mat-form-field-filled-with-label-container-padding-bottom);
	align-items: center;
}

.mdc-notched-outline__leading,
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
	z-index: 0;
	background: var(--background-blue-light);
}

mat-label {
	color: var(--text-color-soft);
	display: block;
	margin-bottom: var(--item-margin-half);
	margin-left: 2px;
	&.required {
		&::after {
			content: '*';
		}
	}
}

/* mat mdc menu content */
.mat-mdc-menu-content {
	padding: var(--container-padding-sm) !important;
}

/* mat icon */
.mat-icon {
	width: var(--icon-size) !important;
	height: var(--icon-size) !important;
	font-size: var(--icon-size) !important;
	line-height: var(--icon-size) !important;
}

/* mat dialog */
.mat-mdc-dialog-container .mdc-dialog__surface {
	border: 1px solid var(--divider-color);
}

/* mat autocomplete */
.mat-mdc-autocomplete-panel {
	border-radius: var(--border-radius) !important;
	margin-top: 4px;
	padding-inline: 0 !important;
	padding-block: 0 !important;

	.mat-mdc-option {
		padding: var(--container-padding-super-sm) !important;
	}
}

.cdk-overlay-backdrop {
	backdrop-filter: blur(5px);
}
