body {
	margin: 0;
	padding: 0;
	font-family: var(--font-family-kone), serif;
	font-size: var(--font-size-normal);
	color: var(--text-color);
	overflow: auto;
	-webkit-font-smoothing: antialiased;
	font-weight: var(--font-weight-normal);
	color-scheme: light;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		height: 100vh;
		width: 100vw;
		z-index: -1;
	}

	&.light-theme:before {
		background:
			linear-gradient(160deg, var(--app-background) 42.33%, transparent 42.33%) top left / cover,
			linear-gradient(160deg, #f5f5f5 59.66%, transparent 59.66%) top left / cover,
			linear-gradient(160deg, transparent 66.66%, #ffffff 66.66%) top left / cover;
	}

	&.dark-theme:before {
		background:
			linear-gradient(160deg, var(--app-background) 42.33%, transparent 42.33%) top left / cover,
			linear-gradient(160deg, #1a1a1a 59.66%, transparent 59.66%) top left / cover,
			linear-gradient(160deg, transparent 66.66%, #111111 66.66%) top left / cover;
	}
}

html.dark-theme,
body.dark-theme {
	color-scheme: dark;
}

/* styles */

a {
	color: var(--primary-color);
	text-decoration: none;
}

.font-family-kone {
	text-transform: uppercase;
}

.input {
	font-family: var(--font-family-kone);
}

.toast-container {
	position: fixed;
	z-index: 999999;
	top: 0 !important;
	width: 100%;
}
