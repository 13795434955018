// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'palette' as appPallete;

// Plus imports for other components in your app.
@import '../styles/styles';
@import '../styles/root-variables';
@import '../styles/flex-layout';
@import '../styles/loading-skeleton';
@import '../styles/view-transitions';
@import '../styles/font-family';
@import '../styles/instant-class';
@import '../styles/animations';
@import '../styles/variable-classes';
@import '@material-design-icons/font/filled.css';
@import '@material-design-icons/font/outlined.css';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single styles file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.m2-define-palette(appPallete.$app-palette);
$app-accent: mat.m2-define-palette(appPallete.$app-accent-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.m2-define-palette(appPallete.$app-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.m2-define-light-theme(
		(
			color: (
				primary: $app-primary,
				accent: $app-accent,
				warn: $app-warn,
			),
		)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);
